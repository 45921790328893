<template>
    <div>
        <!-- <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 基础表格
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div> -->
        <div class="container">
            <div class="handle-box">  
                <el-form ref="form" :model="queryForm" label-width="130px">          
                    <el-form-item label="投放日期">
                        <el-date-picker
                            v-model="queryForm.dateArray"
                            type="daterange"
                            range-separator="至"
                            value-format="yyyy-MM-dd"
                            start-placeholder="年/月/日"
                            end-placeholder="年/月/日">
                        </el-date-picker>
                    </el-form-item>
                    <el-input v-model="queryForm.projectName" placeholder="项目名称" class="handle-input mr10"></el-input>
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
                </el-form>
            </div>
            <el-table
                :data="projectList"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="projectName" label="项目名称" width="355" align="center"></el-table-column>
                <el-table-column prop="budget" label="项目预算(万元)"  align="center"></el-table-column>

                <el-table-column prop="sDate" label="开始日期"></el-table-column>
                <el-table-column prop="eDate" label="结束日期"></el-table-column>
                <!-- <el-table-column label="头像(查看大图)" align="center">
                    <template #default="scope">
                        <el-image
                            class="table-td-thumb"
                            :src="scope.row.imgUrl"
                            :preview-src-list="[scope.row.imgUrl]"
                        ></el-image>
                    </template>
                </el-table-column> -->
                <el-table-column prop="rowTime" label="创建时间"></el-table-column>
                <!-- <el-table-column label="状态" align="center">
                    <template #default="scope">
                        <el-tag
                            :type="
                                scope.row.province === 'Beijing'
                                    ? 'success'
                                    : scope.row.province === 'Tianjin'
                                    ? 'danger'
                                    : ''
                            "
                        >{{ scope.row.state }}</el-tag>
                    </template>
                </el-table-column>

                <el-table-column prop="rowTime" label="注册时间"></el-table-column> -->
                <el-table-column label="操作" width="270" align="center">
                    <template #default="scope">
                        <el-button
                            type="text"
                            icon="el-icon-s-operation"
                            class="blue"
                            @click="handleView(scope.row)"
                        >投放效果</el-button>
                        <!-- <el-button
                            type="text"
                            icon="el-icon-edit"
                            @click="handleEdit(scope.$index, scope.row)"
                        >编辑</el-button> -->
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            class="red"
                            @click="handleDelete(scope.row)"
                        >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.pageIndex"
                    :page-size="query.pageSize"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div> -->
        </div>

        <!-- 编辑弹出框 -->
        <!-- <el-dialog title="编辑" v-model="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="用户名">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="地址">
                    <el-input v-model="form.address"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
            </template>
        </el-dialog> -->

        <!-- <el-dialog title="投放效果" v-model="resultDialogVisible" width="70%" @open="open()"
                             :modal-append-to-body='false'
                             :close-on-click-modal='false'
                             append-to-body>

                  <div>
                    <div class="crumbs">
                        <el-breadcrumb separator="/">
                            <el-breadcrumb-item>
                                <i class="el-icon-pie-chart"></i> 投放效果
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div class="container">
                        <el-table v-loading="loading"  :data="CalculateProjectValueList"
                         :span-method="objectSpanMethod"
                         show-summary
                         border style="width: 100%;margin-bottom: 20px;"
                            height="300">
                            <el-table-column prop="省份" label="省份"></el-table-column>
                            <el-table-column prop="车站" label="车站"></el-table-column>
                            <el-table-column prop="类型" label="类型"></el-table-column>
                            <el-table-column prop="候车大厅" label="候车大厅"></el-table-column>
                            <el-table-column prop="进站安检" label="进站安检"></el-table-column>
                            <el-table-column prop="出站通道" label="出站通道"></el-table-column>
                            <el-table-column prop="进站通道" label="进站通道"></el-table-column>
                            <el-table-column prop="站台" label="站台"></el-table-column>
                            </el-table>
                    </div>
                </div>
                  <template #footer>
                      <span class="dialog-footer">
                          <el-button @click="resultDialogVisible = false">关闭</el-button>
                         
                      </span>
                  </template>
              </el-dialog> -->
        <el-dialog title="投放效果" v-model="resultDialogVisible" width="70%" @open="open()"
                             :modal-append-to-body='false'
                             :close-on-click-modal='false'
                             append-to-body>

                  <div style="margin-top:-30px;">
                    <div class="crumbs">
                        <el-breadcrumb separator="/">
                            <el-breadcrumb-item>
                                <div style="display:flex;">
                                    <i class="el-icon-pie-chart grid-num"></i> 
                                    <div class="grid-num">总体效果</div>
                                </div>
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div>
                        <el-row :gutter="20" class="mgb20">
                            <el-col :span="8">
                                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                    <div class="grid-content grid-con-1">
                                        <i class="el-icon-user-solid grid-con-icon"></i>
                                        <div class="grid-cont-right">
                                            <div class="grid-num">{{CalculateProjectValueList[0].suggestSub[0].item[0].value}}</div>
                                            <div>{{CalculateProjectValueList[0].suggestSub[0].item[0].name}}</div>
                                        </div>
                                    </div>
                                </el-card>
                            </el-col>
                            <el-col :span="8">
                                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                    <div class="grid-content grid-con-2">
                                        <i class="el-icon-message-solid grid-con-icon"></i>
                                        <div class="grid-cont-right">
                                            <div class="grid-num">{{CalculateProjectValueList[0].suggestSub[0].item[1].value}}</div>
                                            <div>{{CalculateProjectValueList[0].suggestSub[0].item[1].name}}</div>
                                        </div>
                                    </div>
                                </el-card>
                            </el-col>
                            <el-col :span="8">
                                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                    <div class="grid-content grid-con-3">
                                        <i class="el-icon-s-goods grid-con-icon"></i>
                                        <div class="grid-cont-right">
                                            <div class="grid-num">{{CalculateProjectValueList[0].suggestSub[0].item[2].value}}</div>
                                            <div>{{CalculateProjectValueList[0].suggestSub[0].item[2].name}}</div>
                                        </div>
                                    </div>
                                </el-card>
                            </el-col>
                            <el-col :span="8">
                                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                    <div class="grid-content grid-con-4">
                                        <i class="el-icon-money grid-con-icon"></i>
                                        <div class="grid-cont-right">
                                            <div class="grid-num">{{CalculateProjectValueList[0].suggestSub[0].item[3].value}}</div>
                                            <div>{{CalculateProjectValueList[0].suggestSub[0].item[3].name}}</div>
                                        </div>
                                    </div>
                                </el-card>
                            </el-col>
                            <el-col :span="8">
                                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                    <div class="grid-content grid-con-5">
                                        <i class="el-icon-coin grid-con-icon"></i>
                                        <div class="grid-cont-right">
                                            <div class="grid-num">{{CalculateProjectValueList[0].suggestSub[0].item[4].value}}</div>
                                            <div>{{CalculateProjectValueList[0].suggestSub[0].item[4].name}}</div>
                                        </div>
                                    </div>
                                </el-card>
                            </el-col>
                            <el-col :span="8">
                                <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                    <div class="grid-content grid-con-6">
                                        <i class="el-icon-truck grid-con-icon"></i>
                                        <div class="grid-cont-right">
                                            <div class="grid-num">{{CalculateProjectValueList[0].suggestSub[0].item[5].value}}</div>
                                            <div>{{CalculateProjectValueList[0].suggestSub[0].item[5].name}}</div>
                                        </div>
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>

                    </div>
                    <el-tabs type="border-card">
                        
                        <el-tab-pane  v-for="cate1 in CalculateProjectValueList.slice(1)"  :key="cate1.title" class="container" :label="cate1.title">
                            
                            <!-- <el-row :gutter="20">    
                                    <el-col :span="8" v-for="cate2 in cate1.suggestSub" :key="cate2.name">
                                        <div class="clearfix">
                                            <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                                <div class="grid-subcontent grid-con-0">
                                                    <i class="el-icon-arrow-right grid-subcon-icon"></i>
                                                    <div class="grid-cont-right">
                                                        <div class="grid-subnum">{{cate2.name}}</div>
                                                     </div>
                                                </div>
                                            </el-card>
                                        </div>
                                        <div v-for="valItem in cate2.item" :key="valItem.name" style="margin-left:60px;">
                                             <el-col :span="12">
                                                <span style="font-weight:bold;">{{valItem.name}}</span>
                                            </el-col>
                                            <el-col :span="12">
                                                <span>{{valItem.value}}</span>
                                            </el-col>
                                        </div>
                                    </el-col>
                            </el-row>
                             -->
                            <el-row :gutter="20">    
                                <!-- <el-col :span="24"> -->
                                <!-- <div v-for="cate2 in cate1.suggestSub" :key="cate2.name"> -->
                                    <el-col :span="8" v-for="cate2 in cate1.suggestSub" :key="cate2.name">
                                        <div class="clearfix">
                                            <!-- <h3>{{cate2.name}}</h3> -->
                                            <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                                <div class="grid-subcontent grid-con-0">
                                                    <i class="el-icon-arrow-right grid-subcon-icon"></i>
                                                    <div class="grid-cont-right">
                                                        <div class="grid-subnum">{{cate2.name}}</div>
                                                        <!-- <div>{{CalculateValueListNew[0].suggestSub[0].item[5].name}}</div> -->
                                                    </div>
                                                </div>
                                            </el-card>
                                        </div>
                                        <div v-for="valItem in cate2.item" :key="valItem.name" style="margin-left:60px;">
                                            <el-row>
                                            <el-col :span="12">
                                                <span style="font-weight:bold;">{{valItem.name}}</span>
                                            </el-col>
                                            <el-col :span="12">
                                                <span>{{valItem.value}}</span>
                                            </el-col>
                                            </el-row>
                                        </div>
                                    </el-col>
                                <!-- </div>   -->
                                <!-- </el-col>-->
                            </el-row>
                        </el-tab-pane>
                        
                    </el-tabs>
                 
                </div>
                  <template #footer>
                      <span class="dialog-footer">
                          <el-button @click="resultDialogVisible = false">返回</el-button>
                      </span>
                  </template>
              </el-dialog>
    </div>
</template>

<script>
// import { getUserList } from "@/api/index";
import { mapGetters } from "vuex";
import {getLatestMonthDateArrayByDate} from "@/utils/time";

export default {
    name: "basetable",
    data() {
        const date = new Date();
        return {
            queryForm: {
                dateArray: getLatestMonthDateArrayByDate({date, delayNum: 3}),
                projectName: "",               
            },
            resultDialogVisible: false,
            multipleSelection: [],
            delList: [],
            editVisible: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1
        };
    },
    computed: {...mapGetters(['projectList','CalculateProjectValueList'])},
    created() {
        this.getData();
    },
    async mounted() {
      
    },

    methods: {
        // 获取 easy-mock 的模拟数据
        async getData() {
            const [sDate, eDate] = this.queryForm.dateArray;
            console.log()
              // fetchData(this.query).then(res => {
            //     console.log(res);
            //     this.tableData = res.list;
            //     this.pageTotal = res.pageTotal || 50;
            // });
            await this.$store.dispatch('operate/projectList',{sDate,eDate,projectName:this.queryForm.projectName});
            // getUserList({userId:1,sDate:'2021.7.5',eDate:'2021.8.5', key : ''}).then(res => {
            //     console.log('res',res)
            //     this.tableData = res.data;
            //     this.pageTotal = res.data.length || 50;
            // })
        },

        async handleView(item){
            const loading = this.$loading({
                lock: true,
                text: '拼命计算中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            await this.$store.dispatch('operate/CalculateProjectValueList',{projectId:item.projectId}).then(res=>{
                // this.$refs.Table.doLayout();
                // console.log('CalculateProjectValueList',this.CalculateProjectValueList)
                loading.close();
            });
            this.resultDialogVisible = true;
        },

        // 触发搜索按钮
        handleSearch() {
            // this.$set(this.query, "pageIndex", 1);
            this.getData();
        },
        // 删除操作
        handleDelete(item) {
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning"
            })
                .then(
                    // () => {
                    //     this.$store.dispatch('operate/DelProject', {projectId: item.projectId});
                    //     this.$message.success("删除成功");
                    // this.tableData.splice(index, 1);
                // }
                    async () => {                        
						await this.$store.dispatch('operate/DelProject', {projectId: item.projectId});
                        this.getData();
					}
                ).then(async () => {
										Message.success('删除成功!');
										

								})
                .catch(() => {});
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = "";
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + " ";
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 编辑操作
        handleEdit(index, row) {
            this.idx = index;
            this.form = row;
            this.editVisible = true;
        },
        // 保存编辑
        saveEdit() {
            this.editVisible = false;
            this.$message.success(`修改第 ${this.idx + 1} 行成功`);
            this.$set(this.tableData, this.idx, this.form);
        },
        // 分页导航
        handlePageChange(val) {
            // this.$set(this.query, "pageIndex", val);
            this.getData();
        },

        open(){
        console.log('do open')
        this.$nextTick(() => {
        //  执行echarts方法
        //   this.initEcharts()
        })
      },
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.content-title {
    clear: both;
    font-weight: 400;
    line-height: 50px;
    margin: 10px 0;
    font-size: 22px;
    color: #1f2f3d;
}


.grid-content {
    display: flex;
    align-items: center;
    height: 100px;
}

.grid-subcontent {
    display: flex;
    align-items: center;
    height: 50px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 30px;
    font-weight: bold;
}
.grid-subnum {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
}
.grid-subcon-icon{
    font-size: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #fff;
}

.grid-con-0 .grid-subcon-icon {
    background: rgb(184, 223, 223);
}

.grid-con-0 .grid-num {
    color: rgb(184, 223, 223);
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}

.grid-con-4 .grid-con-icon {
    background: rgb(132, 94, 67);
}

.grid-con-4 .grid-num {
    color: rgb(132, 94, 67);
}

.grid-con-5 .grid-con-icon {
    background: rgb(66, 94, 67);
}

.grid-con-5 .grid-num {
    color: rgb(66, 94, 67);
}

.grid-con-6 .grid-con-icon {
    background: rgb(66, 136, 67);
}

.grid-con-6 .grid-num {
    color: rgb(66, 136, 67);
}

.clearfix{
    margin-top: 20px;
}
</style>
